import axios from 'axios'
import { getLanguageForCode } from '../helpers/languages'

export const ErrNoTranslation = new Error('NoTranslation')
export const ErrUnknown = new Error('Unknown')

let rooms = null
const PROD_SERVER = 'https://connect.vox.network'
const STAGING_SERVER = 'https://voxconnect-staging.herokuapp.com'
const LOGS_SERVER = 'https://logs.toursystems.guide'

const initLogs = axios.create({
  baseURL: LOGS_SERVER,
  headers: {
    Accept: `application/json`,
    Authorization: `Basic dm94Onl3QjZEVCVURyRwRVZH`,
  },
})

const fetchAudios = axios.create({
  baseURL: 'https://popguide.herokuapp.com',
  headers: {
    Accept: `application/json`,
  },
})

const initRooms = (isProd) => {
  const server = isProd ? PROD_SERVER : STAGING_SERVER
  const basePath = server + '/connect_api/v1'
  return axios.create({
    baseURL: basePath,
    headers: {
      Accept: `application/json`,
    },
  })
}

export const getAudioList = (popmapId, primaryStreamId) => {
  let path = `/wave/light_audio_by_popmap?pop_map_id=${popmapId}`
  const promise = fetchAudios.get(path)
  const dataPromise = promise.then((response) => {
    return response.data.langauges.map((lang) => {
      const language = getLanguageForCode(lang)
      return {
        id: primaryStreamId,
        name: language.name,
        image: language.flagImage,
        lang_id: lang,
      }
    })
  })
  return dataPromise
}

export const fetchTranslatorRooms = (streamId, isProd) => {
  const data = {
    room_id: streamId,
  }

  let path = '/listener_room_translations'
  if (rooms === null) {
    rooms = initRooms(isProd)
  }
  const promise = rooms.post(path, data)
  const dataPromise = promise
    .then((response) => response)
    .catch((error) => {
      const status = error.response.status
      if (status !== 'undefined' && status === 500) {
        console.log(error.response.status)
        throw ErrNoTranslation
      } else {
        throw ErrUnknown
      }
    })

  return dataPromise
}

export const postLogs = (data) => {
  data['user_agent'] = window.navigator.userAgent
  const payload = [
    {
      source: 'web',
      data: data,
    },
  ]
  const promise = initLogs.post('', payload)
  const dataPromise = promise
    .then((response) => response)
    .catch((error) => {
      console.log('error in posting logs: ' + error)
    })
  return promise
}
