import voxLogo from '../../images/vox-logo.svg';
import closeBtnInfo from '../../images/v1/close-btn.svg';
import { AnimatePresence, motion } from 'framer-motion';

const infoModalWrapperAnimation = {
    visible: { opacity: 1},
    hidden: { opacity: 0}
    // visible: { opacity: 1, y: 0 },
    // hidden: { opacity: 0, y: "100%" }
}

export const InfoModal = ({show, close}) => {
    return (
        <AnimatePresence>
            {show && (
                <motion.div className='infoModalWrapper'
                    key="infoModal"
                    variants={infoModalWrapperAnimation}
                    initial="hidden"
                    animate="visible"
                    transition={{ ease: "easeIn", duration: 0.1 }}
                    exit={{ opacity: 0, transition:{ ease: "easeIn", duration: 0.1 }}}
                    // exit={{ opacity: 0, y:"100%", transition:{ ease: "easeIn", duration: 0.1 }}}
                >
                    <div className='infoModalBackground'>
                        <div className='infoModalContainer'>
                            <div className='close-btn-info' onClick={()=>close()}>
                                <img src={closeBtnInfo}  alt="logo" />
                            </div>
                            <div style={{"fontWeight":"600", "fontSize":"18px"}}>
                                Version
                            </div>
                            <div style={{"fontWeight":"600", "fontSize":"18px"}}>
                                1.0.{window.VERSION.slice(0,8)}
                            </div>
                            <div style={{"marginTop":"25px"}}>
                               <a href='https://it.voxtours.com/vox-connect-service-privacy-policy/' target='_blank' style={{"color":"#0d75a0", "fontWeight":"600", "fontSize":"16px"}}>Privacy Policy</a> 
                            </div>
                            <div style={{"marginTop":"5px", "marginBottom":"10px"}}>
                               <a href='https://www.voxtours.com/vox-connect-service-terms-conditions/' target='_blank' style={{"color":"#0d75a0", "fontWeight":"600", "fontSize":"16px"}}>Terms of Use</a> 
                            </div>
                            <div style={{"marginTop":"5px", "marginBottom":"35px", "fontSize":"12px", "color":"#7c7da0", "lineHeight":"16px"}}>
                                © 2023 <a href='#' style={{"color":"#7c7da0"}}>Vox Group.</a>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}
