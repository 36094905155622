import {
  FloatingArrow,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  useTransitionStyles,
} from '@floating-ui/react'
import { forwardRef, useRef } from 'react'
import ImgChevronRight from './images/v1/right-arrow.svg'
import './tooltips.css'

const fill = 'rgba(0, 0, 0, 0.30)'
const ARROW_WIDTH = 30
const ARROW_HEIGHT = 15

export const Tooltip = forwardRef(
  (
    {
      content,
      floatingStyles,
      styles,
      context,
      ARROW_WIDTH,
      ARROW_HEIGHT,
      fill,
      handleTooltipDismiss,
      handleTooltipNext,
    },
    propRef,
  ) => {
    const { refs, arrowRef } = propRef
    return (
      <div ref={refs.setFloating} style={floatingStyles}>
        <div style={styles} className='tooltipContainer'>
          <div className='tooltipHeader'>
            <div className='tooltipTitle'>{content.title}</div>
            <div className='tooltipNumber'>
              {content.numerator}/{content.demominator}
            </div>
          </div>
          <div className='tooltipContent'>
            {content.text.split('\n').map((line, index) => (
              <>
                <p key={index} className='tooltipContentLine'>
                  {line}
                </p>
                {/*{index !== content.text.split("\n").length - 1 && <br />}*/}
              </>
            ))}
          </div>
          <div className='tooltipFooter'>
            <div onClick={handleTooltipDismiss}>{content.dismiss}</div>
            <div onClick={handleTooltipNext}>
              {content.numerator === content.demominator ? content.done : content.next}
              <img src={ImgChevronRight} style={{ width: '20px', height: '20px' }} />
            </div>
          </div>
          <FloatingArrow
            ref={arrowRef}
            context={context}
            width={ARROW_WIDTH}
            height={ARROW_HEIGHT}
            fill={fill}
          />
        </div>
      </div>
    )
  },
)

export const useTooltip = ({ open, onOpenChange, placement }) => {
  const arrowRef = useRef(null)
  const { refs, floatingStyles, context, middlewareData } = useFloating({
    open: open,
    onOpenChange: onOpenChange,
    placement: placement,
    // make sure tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(ARROW_HEIGHT),
      flip({
        fallbackAxisSideDirection: 'start',
        padding: 5,
      }),
      shift({
        padding: 5,
      }),
      arrow({
        element: arrowRef,
      }),
    ],
  })
  const arrowX = middlewareData.arrow?.x ?? 0
  const arrowY = middlewareData.arrow?.y ?? 0
  const transformX = arrowX + ARROW_WIDTH / 2
  const transformY = arrowY + ARROW_HEIGHT

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: 'scale(0.8)',
    },
    common: ({ side }) => ({
      transformOrigin: {
        top: `${transformX}px calc(100% + ${ARROW_HEIGHT}px)`,
        bottom: `${transformX}px ${-ARROW_HEIGHT}px`,
        left: `calc(100% + ${ARROW_HEIGHT}px) ${transformY}px`,
        right: `${-ARROW_HEIGHT}px ${transformY}px`,
      }[side],
    }),
  })

  const ref = {
    refs: refs,
    arrowRef: arrowRef,
  }
  return { ref, floatingStyles, styles, isMounted, context, fill, ARROW_HEIGHT, ARROW_WIDTH }
}
