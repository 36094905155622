import { Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { onValue, ref, set } from 'firebase/database'
import { AnimatePresence, motion } from 'framer-motion'
import { Howl } from 'howler'
import Lottie from 'lottie-react'
import { React, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './Player.css'
import { postLogs } from './axios/requests'
import { InfoModal } from './components/modals/info'
import animationData from './equalizer.json'
import { db, voxauth } from './firebaseConfig'
import { Log } from './helpers/logger'
import placeholder from './images/placeholder.jpg'
import airplaneSvg from './images/v1/airplane.svg'
import closebtnv1 from './images/v1/close-btn.svg'
import infoBtn from './images/v1/info_blue_24.svg'
import { default as messagesSvg, default as newMessageSvg } from './images/v1/listener_message.svg'
import penSvg from './images/v1/pen.svg'
import notificationSvg from './images/v1/presenter_message.svg'
import notificationSvgRedDot from './images/v1/presenter_message_red_dot.svg'
import leaveStream from './images/v1/stop_stream.svg'
import logo from './images/v1/vox_logo_small.svg'
import WebRTCAdaptor from './js/webrtc_adaptor'
import { Tooltip, useTooltip } from './streamplayerTooltips'

const voxModalWrapperAnimation = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: '100%' },
}

const TOTAL_TOOLTIPS = 4

const StreamPlayer = (props) => {
  const timer = useRef(null)
  const scrollContainerRef = useRef(null)
  const history = useHistory()
  const howler = useRef(null)
  const webRTCAdaptor = useRef(null)
  const unsubscribeAudiosFirebaseListener = useRef(null)
  const unsubscribePresenterMessagesFirebaseListener = useRef(null)
  const unsubscribeListenerMessagesFirebaseListener = useRef(null)

  // init translations
  const { t, i18n } = useTranslation()
  useEffect(() => {
    const lng = navigator.language
    i18n.changeLanguage(lng)
  }, [])

  const showTooltip = () => {
    let show = localStorage.getItem('showTooltip')
    if (!show || (show !== 'true' && show !== 'false')) {
      show = 'true'
      localStorage.setItem('showTooltip', show)
    }
    return show === 'true'
  }

  const showCaptions = sessionStorage.getItem("ShowCaptions") === "true"
  const hasTranslation = sessionStorage.getItem('HasTranslation') === 'yes'

  const [tooltipsActive, setTooltipsActive] = useState(showTooltip)
  const [tooltipsChangeLanguageIsActive, setTooltipsChangeLanguageIsActive] = useState(
    hasTranslation ? showTooltip() : false,
  )
  const [tooltipsSharedFilesIsActive, setTooltipsSharedFilesIsActive] = useState(
    hasTranslation ? false : showTooltip(),
  )
  const [tooltipsMessageIsActive, setTooltipsMessageIsActive] = useState(false)
  const [tooltipsStopStreamIsActive, setTooltipsStopStreamIsActive] = useState(false)

  const handleTooltipDismiss = () => {
    localStorage.setItem('showTooltip', 'false')
    setTooltipsMessageIsActive(false)
    setTooltipsStopStreamIsActive(false)
    setTooltipsSharedFilesIsActive(false)
    setTooltipsChangeLanguageIsActive(false)
    setTooltipsActive(false)
  }

  const handleTooltipNext = () => {
    if (tooltipsChangeLanguageIsActive) {
      setTooltipsStopStreamIsActive(false)
      setTooltipsMessageIsActive(false)
      setTooltipsChangeLanguageIsActive(false)
      setTooltipsSharedFilesIsActive(true)
      return
    }

    if (tooltipsSharedFilesIsActive) {
      setTooltipsStopStreamIsActive(false)
      setTooltipsSharedFilesIsActive(false)
      setTooltipsChangeLanguageIsActive(false)
      setTooltipsMessageIsActive(true)
      return
    }

    if (tooltipsMessageIsActive) {
      setTooltipsMessageIsActive(false)
      setTooltipsSharedFilesIsActive(false)
      setTooltipsChangeLanguageIsActive(false)
      setTooltipsStopStreamIsActive(true)
      return
    }

    setTooltipsActive(false)
    setTooltipsMessageIsActive(false)
    setTooltipsStopStreamIsActive(false)
    setTooltipsSharedFilesIsActive(false)
    setTooltipsChangeLanguageIsActive(false)
    localStorage.setItem('showTooltip', 'false')
  }

  const totalTooltips = hasTranslation ? TOTAL_TOOLTIPS : TOTAL_TOOLTIPS - 1

  const stopStreamTooltip = useTooltip({
    open: tooltipsStopStreamIsActive,
    onOpenChange: setTooltipsStopStreamIsActive,
    placement: 'top',
  })
  const stopStreamTooltipContent = {
    dismiss: t('tooltips.dismiss'),
    next: t('tooltips.next'),
    done: t('tooltips.done'),
    title: t('tooltips.stopTheStream.title'),
    numerator: hasTranslation ? 4 : 3,
    demominator: totalTooltips,
    text: t('tooltips.stopTheStream.description'),
  }
  const messagesTooltip = useTooltip({
    open: tooltipsMessageIsActive,
    onOpenChange: setTooltipsMessageIsActive,
    placement: 'top',
  })
  const messagesTooltipContent = {
    dismiss: t('tooltips.dismiss'),
    next: t('tooltips.next'),
    done: t('tooltips.done'),
    title: t('tooltips.sendQuestionsOrComments.title'),
    numerator: hasTranslation ? 3 : 2,
    demominator: totalTooltips,
    text: t('tooltips.sendQuestionsOrComments.description'),
  }
  const sharedFilesTooltip = useTooltip({
    open: tooltipsSharedFilesIsActive,
    onOpenChange: setTooltipsSharedFilesIsActive,
    placement: 'bottom',
  })
  const sharedFilesTooltipContent = {
    dismiss: t('tooltips.dismiss'),
    next: t('tooltips.next'),
    done: t('tooltips.done'),
    title: t('tooltips.sharedFiles.title'),
    numerator: hasTranslation ? 2 : 1,
    demominator: totalTooltips,
    text: t('tooltips.sharedFiles.description'),
  }
  const changeLanguageTooltip = useTooltip({
    open: tooltipsChangeLanguageIsActive,
    onOpenChange: setTooltipsChangeLanguageIsActive,
    placement: 'bottom',
  })
  const changeLanguageTooltipContent = {
    dismiss: t('tooltips.dismiss'),
    next: t('tooltips.next'),
    done: t('tooltips.done'),
    title: t('tooltips.switchLanguage.title'),
    numerator: 1,
    demominator: TOTAL_TOOLTIPS,
    text: t('tooltips.switchLanguage.description'),
  }

  const [state, setState] = useState({
    mediaConstraints: {
      video: false,
      audio: false,
    },
    streamName: '',
    token: '',
    pc_config: {
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302',
        },
      ],
    },
    sdpConstraints: {
      OfferToReceiveAudio: true,
      OfferToReceiveVideo: true,
    },
    websocketURL: 'wss://dashboard.toursystems.guide:5443/VoxConnect/websocket',
    isWaiting: false,
    streamConnected: false,
    hasTranslation: false,
    currentAudio: null,
    hasPlayError: false,
    trackTitle: '',
    trackDisplay: false,
    presenterMessagesParsed: [],
    listenerMessagesParsed: [],
    showInfo: false,
    showFiles: false,
    showMessages: false,
    showNewMessage: false,
    showImage: false,
    showImageUrl: '',
    isPdf: false,
    hasUnreadMessages: true,
    howlerActivePlayerId: 0,
    isFadeAnimationEnabled: false,
    flagImg: null,
    langName: null,
    currentTrackDuration: null,
    audioProgress: null,
    isTrackPlaying: false,
    isModalActive: false,
    transcription: '',
    showTranscription: showCaptions,
  })

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = scrollContainerRef.current.scrollTop
      console.log('scroll: top position is at:', scrollPosition)
    }
    const scrollControl = state.isModalActive ? scrollContainerRef : null

    if (scrollControl && state.isModalActive === true) {
      scrollControl.current.addEventListener('scroll', handleScroll)
      console.log('scroll: added eventListener to scroll:', scrollControl)

      const scrollPosition = scrollContainerRef.current.scrollTop
      console.log('scroll: top position is at:', scrollPosition)
    }

    return () => {
      if (scrollControl && scrollControl.current && scrollControl.current.removeEventListener) {
        scrollControl.current.removeEventListener('scroll', handleScroll)
        console.log('scroll: removed eventListener to scroll on unload')
      }
    }
  }, [state.isModalActive])

  useEffect(() => {
    const streamId = sessionStorage.getItem('SelectedStream')
    const hasTranslation = sessionStorage.getItem('HasTranslation')
    const langId = sessionStorage.getItem('LangId')
    const flagImg = sessionStorage.getItem('LangFlag')
    const langName = sessionStorage.getItem('LangName')
    const showCaptions = sessionStorage.getItem("ShowCaptions") === "true"

    sessionStorage.removeItem('VoxConnectListenerIdAuthorized')

    setState((prevState) => {
      return {
        ...prevState,
        streamName: streamId,
        streamConnected: false,
        isWaiting: true,
        trackDisplay: false,
        isTrackPlaying: false,
        hasTranslation: hasTranslation === 'yes',
        flagImg: flagImg,
        langName: langName,
      }
    })

    initializeStreamEventLog()

    webRTCAdaptor.current = initiateWebrtc(streamId, false, showCaptions)
    // webRTCAdaptor.current = initiateWebrtc(streamId, (langId !== "undefined"));

    return () => {
      howler.current?.stop()
      howler.current = null
      if (unsubscribeAudiosFirebaseListener.current) {
        unsubscribeAudiosFirebaseListener.current()
        unsubscribeAudiosFirebaseListener.current = null
      }
      if (unsubscribePresenterMessagesFirebaseListener.current) {
        unsubscribePresenterMessagesFirebaseListener.current()
        unsubscribePresenterMessagesFirebaseListener.current = null
      }
      if (unsubscribeListenerMessagesFirebaseListener.current) {
        unsubscribeListenerMessagesFirebaseListener.current()
        unsubscribeListenerMessagesFirebaseListener.current = null
      }
      webRTCAdaptor.current?.disconnectFromRoom(state.streamName)
      webRTCAdaptor.current = null
      signOut(voxauth)
        .then(() => {
          Log.d('logged out')
        })
        .catch((error) => {
          Log.e('error when logging out:', error)
        })

      sessionStorage.removeItem('VoxConnectListenerIdAuthorized')
      setState((prevState) => {
        return {
          ...prevState,
          streamName: streamId,
          streamConnected: false,
          isWaiting: true,
        }
      })
    }
  }, [])

  const warningAlert = (message) => {
    // window.alert("Connections Limit Reached!");
    toast.error(message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    })
  }

  const subscribeListenerMessages = (streamId) => {
    if (unsubscribeListenerMessagesFirebaseListener.current != null) return

    const listenerMessagesRef = ref(db, 'v2/' + streamId + '/listener_messages')
    console.log('checking listenerMessages')
    unsubscribeListenerMessagesFirebaseListener.current = onValue(
      listenerMessagesRef,
      (snapshot) => {
        const data = snapshot.val()
        Log.d('listenerMessages data:', data)
        const listenerId = sessionStorage.getItem('VoxConnectListenerId')

        if (data) {
          const dataKeys = Object.keys(data)
          const filteredDataKeys = dataKeys.filter((elem, index, array) => {
            return data[elem]['id'] === listenerId
          })
          const parsedData = filteredDataKeys.map((elem, index, array) => {
            if (data[elem]['id'] === listenerId) {
              Log.d('messages inside filter:', data[elem])
              return {
                [elem]: { ...data[elem] },
              }
            }
          })
          Log.d('listener messages parsedData:', parsedData)
          setState((prevState) => {
            return {
              ...prevState,
              listenerMessagesParsed: parsedData,
            }
          })
        } else {
          setState((prevState) => {
            return {
              ...prevState,
              listenerMessagesParsed: [],
            }
          })
        }
      },
    )
  }

  const subscribePresenterMessages = (streamId) => {
    if (unsubscribePresenterMessagesFirebaseListener.current != null) return

    const presenterMessagesRef = ref(db, 'v2/' + streamId + '/presenter_messages')
    Log.d('checking presenterMessages')
    unsubscribePresenterMessagesFirebaseListener.current = onValue(
      presenterMessagesRef,
      (snapshot) => {
        const data = snapshot.val()
        Log.d('presenterMessages data:', data)

        if (data) {
          const dataKeys = Object.keys(data)
          const parsedData = dataKeys.map((elem) => {
            return {
              [elem]: { ...data[elem] },
            }
          })

          Log.d('messages parsedData:', parsedData)
          receivedFileEventLog()
          setState((prevState) => {
            return {
              ...prevState,
              presenterMessagesParsed: parsedData,
              hasUnreadMessages: true,
            }
          })
        }
      },
    )
  }

  const subscribeAudioMessages = (langId, streamId) => {
    if (unsubscribeAudiosFirebaseListener.current != null) return

    const audiosRef = ref(db, 'v2/' + streamId + '/audios')
    unsubscribeAudiosFirebaseListener.current = onValue(audiosRef, (snapshot) => {
      const data = snapshot.val()
      if (data) {
        Log.d('@@@@@@@@@:data:', data)

        let audioIds = Object.keys(data)
        let activeId = audioIds.find((id) => data[id].playing === true)
        if (activeId) {
          try {
            howler.current?.stop()
            setState((prevState) => {
              return {
                ...prevState,
                isTrackPlaying: false,
                audioProgress: 0,
              }
            })
            receivedAudioEventLog(activeId, [data[activeId]['languages'][langId].title])
            const howlInst = new Howl({
              src: [data[activeId]['languages'][langId].url],
              html5: true,
              onplay: () => {
                console.log("@@@ I'm playing!!!")
                // requestAnimationFrame(updateProgressBar)
                setState((prevState) => {
                  return {
                    ...prevState,
                    isTrackPlaying: true,
                    audioProgress: 0,
                  }
                })
              },
            })
            setState((prevState) => {
              return {
                ...prevState,
                trackTitle: [data[activeId]['languages'][langId].title],
                trackDisplay: true,
                currentTrackDuration: howlInst.duration(),
              }
            })

            howler.current = howlInst

            howlInst.once('playerror', function (id, error) {
              Log.d('Audio Play Error: ' + error)

              setState((prevState) => {
                return {
                  ...prevState,
                  hasPlayError: true,
                }
              })
            })

            howlInst.once('unlock', function () {
              Log.d('Audio Unlocked')
            })

            howlInst.on('end', function () {
              setState((prevState) => {
                return {
                  ...prevState,
                  trackDisplay: false,
                  isTrackPlaying: false,
                }
              })
            })

            const howlerActivePlayerId = howlInst.play()
            setState((prevState) => {
              return {
                ...prevState,
                howlerActivePlayerId: howlerActivePlayerId,
              }
            })
          } catch (e) {}
        }
      }
    })
  }

  const handleInfoModalToggle = () => {
    if (tooltipsActive) {
      return
    }
    setState((prevState) => {
      return {
        ...prevState,
        showInfo: !prevState.showInfo,
      }
    })
  }

  const handleUserAuth = (email, password) => {
    const langId = sessionStorage.getItem('LangId')
    const streamId = sessionStorage.getItem('SelectedStream')
    const currentUser = voxauth.currentUser

    if (currentUser != null) {
      const userEmail = currentUser.email
      Log.d('User is already logged in with email: ' + userEmail)

      if (userEmail === email.toLowerCase()) {
        
        alreadyLoggedInEventLog()

        Log.d('Current user is valid for the stream')
        unmute()
        subscribeAudioMessages(langId, streamId)
        subscribePresenterMessages(streamId)
        subscribeListenerMessages(streamId)

        setState((prevState) => {
          return {
            ...prevState,
            isWaiting: false,
            streamConnected: true,
          }
        })

        return
      }
    }

    loginToRoomEventLog()

    console.log('user signing creds: ', email, password)

    signInWithEmailAndPassword(voxauth, email, password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user
        Log.d('logged in:', user)

        authResponseSuccessEventLog()

        const streamId = sessionStorage.getItem('SelectedStream')

        sessionStorage.setItem(
          'VoxConnectListenerIdAuthorized',
          sessionStorage.getItem('VoxConnectListenerId'),
        )

        unmute()
        setState((prevState) => {
          return {
            ...prevState,
            isWaiting: false,
            streamConnected: true,
          }
        })
        subscribeAudioMessages(langId, streamId)
        subscribePresenterMessages(streamId)
        subscribeListenerMessages(streamId)
        // ...
      })
      .catch((error) => {
        var errorCode = error.code
        var errorMessage = error.message
        authResponseFailureEventLog()
        Log.e('Not logged in. No user :', errorCode, errorMessage)
        setState((prevState) => {
          return {
            ...prevState,
            isWaiting: true,
            trackDisplay: false,
            isTrackPlaying: false,
            streamConnected: false,
          }
        })
      })
  }

  const onStartPlaying = (name) => {
    webRTCAdaptor.current?.joinRoom(state.streamName)
  }

  const unmute = () => {
    const langId = sessionStorage.getItem('LangId')
    if (langId === 'undefined') {
      webRTCAdaptor.current?.unmuteRemoteAudio()
    }
  }
  const initiateWebrtc = (streamId, startMuted, shouldTranscript): WebRTCAdaptor => {
    return new WebRTCAdaptor({
      websocket_url: state.websocketURL,
      mediaConstraints: state.mediaConstraints,
      peerconnection_config: state.pc_config,
      sdp_constraints: state.sdpConstraints,
      remoteVideoId: 'remoteVideo',
      isPlayMode: true,
      debug: true,
      candidateTypes: ['tcp', 'udp'],
      startMuted: startMuted,
      shouldTranscript: shouldTranscript,
      callback: (info, obj) => {
        console.log('info:$$$:', info, ' ::callback data:^^^^^^^^^^:', obj)
        if (info === 'initialized') {
          // console.log("initialized");
          webRTCAdaptor.current.joinRoom(streamId)
        } else if (info === 'transcript') {
          console.log('=== Transcript === \n' + obj)

          setState((prevState) => {
            return {
              ...prevState,
              transcription: obj,
            }
          })

          // Reset timer if a new transcription is received before the timeout
          clearTimeout(timer.current)
          // Set timer for hiding the transcription after 5 seconds
          timer.current = setTimeout(() => {
            setState((prevState) => {
              return {
                ...prevState,
                transcription: '',
              }
            })
          }, 5000)
        } else if (info === 'data_channel_opened') {
          const listener_id = sessionStorage.getItem('VoxConnectListenerId')

          requestAuthEventLog()

          const msg = {
            request: 'request_auth',
            listener_id: listener_id,
          }
          console.log('data channel: streamId:', obj)
          console.log('data channel: connections:', webRTCAdaptor.current.remotePeerConnection)
          webRTCAdaptor.current.sendData(obj, btoa(JSON.stringify(msg)))
        } else if (info === 'joinedTheRoom') {

          setState((prevState) => {
            return {
              ...prevState,
              isWaiting: true,
              trackDisplay: false,
              isTrackPlaying: false,
            }
          })
        } else if (info === 'play_started') {
          joinRoomSuccessEventLog()

          setState((prevState) => {
            return {
              ...prevState,
              isWaiting: false,
              streamConnected: true,
            }
          })

        } else if (info === 'play_finished') {
          //leaved the stream
          Log.d('play finished')
          setState((prevState) => {
            return {
              ...prevState,
              isWaiting: true,
              trackDisplay: false,
              isTrackPlaying: false,
              streamConnected: false,
            }
          })

          howler.current?.stop()
        } else if (info === 'closed') {
          //console.log("Connection closed");
          if (typeof obj != 'undefined') {
            // console.log("Connecton closed: "
            //     + JSON.stringify(obj));
          }
        } else if (info === 'ice_connection_state_changed') {
          // console.log("iceConnectionState Changed: ", JSON.stringify(obj));
        } else if (info === 'updated_stats') {
          //obj is the PeerStats which has fields
          //averageIncomingBitrate - kbits/sec
          //currentIncomingBitrate - kbits/sec
          //packetsLost - total number of packet lost
          //fractionLost - fraction of packet lost
          // console.log("Average incoming kbits/sec: " + obj.averageIncomingBitrate
          //     + " Current incoming kbits/sec: " + obj.currentIncomingBitrate
          //     + " packetLost: " + obj.packetsLost
          //     + " fractionLost: " + obj.fractionLost
          //     + " audio level: " + obj.audioLevel);
        } else if (info === 'data_received') {
          const streamId = sessionStorage.getItem('SelectedStream')
          console.log('data_received: data:', obj.event.data)
          const data = JSON.parse(atob(obj.event.data))

          if (data.email && data.password) {
            Log.d('Received Auth Credentials')
            console.log('LISTENER COUNT: Received Auth credentials')
            handleUserAuth(data.email, data.password)
          }

          if (data.request && data.request === 'ping') {
            const listener_id = sessionStorage.getItem('VoxConnectListenerIdAuthorized')

            if (listener_id === null) {
              console.log('LISTENER COUNT: received ping. But NOT sending pong')
            } else {
              const msg = {
                request: 'pong_response',
                listener_id: listener_id,
              }
              console.log('LISTENER COUNT: received ping. sendng pong:listenr_id:' + listener_id)
              webRTCAdaptor.current.sendData(obj.streamId, btoa(JSON.stringify(msg)))
            }
          }

          if (data.error_type && data.error_type === 'connections_limit') {
            console.log('LISTENER COUNT: connection_limit error received')
            const listener_id = sessionStorage.getItem('VoxConnectListenerId')
            console.log('ROOM FULL: local_listenerID:', listener_id)
            console.log('ROOM FULL: received_listenerID:', data.listener_id)
            if (listener_id === data.listener_id) {
              warningAlert('Connections Limit Reached!')
              props.history.replace('/')
            }
          }
        } else if (info === 'bitrateMeasurement') {
          // const data = JSON.parse(atob(obj.event?.data))
          // console.log(info + " notification received");

          console.log(obj)
        } else {
          // console.log(info + " notification received");
        }
      },
      callbackError: function (error) {
        //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

        Log.e('error callback: ' + JSON.stringify(error))
        // alert(JSON.stringify(error));
      },
    })
  }

  const mainView = () => {
    const { streamName, isWaiting, streamConnected, hasPlayError } = state
    if (hasPlayError) {
      return <h2> {t('streamPlayerPage.tapTheButton')} </h2>
    } else if (streamConnected) {
      return (
        <>
          <div className='flagImageWrapper'>
            <img src={state.flagImg} alt='flag' style={{ width: '24px', height: '24px' }} />
            <div>{state.langName}</div>
          </div>
          <h1> {streamName} </h1>
        </>
      )
    } else if (isWaiting) {
      sessionStorage.removeItem('VoxConnectListenerIdAuthorized')
      return (
        <>
          <div className='flagImageWrapper'>
            <img src={state.flagImg} alt='flag' style={{ width: '24px', height: '24px' }} />
            <div>{state.langName}</div>
          </div>
          <h2> {t('streamPlayerPage.waitingForPres')} </h2>
        </>
      )
    } else {
      return <h1 />
    }
  }

  const buttonView = () => {
    const { streamName, isWaiting, streamConnected, hasPlayError } = state

    if (hasPlayError) {
      return (
        <button
          onClick={(aaa) => {
            let activeHowlId = 0
            setState((state) => {
              activeHowlId = state.howlerPlayId
              return state
            })
            const isPlaying = howler.current.playing(activeHowlId)
            if (!isPlaying) {
              const howlerActivePlayerId = howler.current?.play()
              // requestAnimationFrame(updateProgressBar)
              setState((prevState) => {
                return {
                  ...prevState,
                  howlerActivePlayerId: howlerActivePlayerId,
                  isTrackPlaying: true,
                }
              })
            }
            setState((prevState) => {
              return {
                ...prevState,
                hasPlayError: false,
              }
            })

            subscribeAudioMessages()
          }}
          className='btn-primary'
          id='start_play_button'
        >
          {t('streamPlayerPage.startPlayingAudio')}
        </button>
      )
    } else if (streamConnected || isWaiting) {
      return (
        <>
          <div
            ref={stopStreamTooltip.ref.refs.setReference}
            onClick={(aaa) => {
              if (tooltipsActive) {
                return
              }
              webRTCAdaptor.current?.leaveFromRoom(streamName)
              // props.history.replace("/")
              history.goBack()
              history.goBack()
            }}
            className='close-stream'
            id='start_play_button'
          >
            <div style={{ alignSelf: 'center' }}>{t('streamPlayerPage.disconnect')}</div>
            <div style={{ alignSelf: 'center' }}>
              <img src={leaveStream} />
            </div>
          </div>
          {stopStreamTooltip.isMounted && (
            <Tooltip
              ref={stopStreamTooltip.ref}
              floatingStyles={stopStreamTooltip.floatingStyles}
              styles={stopStreamTooltip.styles}
              content={stopStreamTooltipContent}
              ARROW_HEIGHT={stopStreamTooltip.ARROW_HEIGHT}
              ARROW_WIDTH={stopStreamTooltip.ARROW_WIDTH}
              fill={stopStreamTooltip.fill}
              handleTooltipDismiss={handleTooltipDismiss}
              handleTooltipNext={handleTooltipNext}
              context={stopStreamTooltip.context}
            />
          )}
        </>
      )
    } else {
      return (
        <div onClick={onStartPlaying(streamName)} className='close-stream' id='start_play_button'>
          <div style={{ alignSelf: 'center' }}>{t('streamPlayerPage.startPlaying')}</div>
          <div style={{ alignSelf: 'center' }}>
            <img src={leaveStream} />
          </div>
        </div>
      )
    }
  }

  const handleMessagesViewShow = () => {
    console.log('handleMessagesViewShow fired')
    console.log('listener messages data:', state.listenerMessagesParsed)
    if (tooltipsActive) {
      return
    }
    setState((prevState) => {
      return {
        ...prevState,
        showMessages: true,
      }
    })
  }

  const handleMessagesViewClose = () => {
    console.log('handleMessagesViewClose fired')
    setState((prevState) => {
      return {
        ...prevState,
        showMessages: false,
      }
    })
  }

  const handleNewMessageViewShow = () => {
    setState((prevState) => {
      return {
        ...prevState,
        // showMessages: false,
        showNewMessage: true,
      }
    })
  }

  const handleNewMessageViewClose = () => {
    setState((prevState) => {
      return {
        ...prevState,
        // showMessages: true,
        showNewMessage: false,
      }
    })
  }

  const writeListenerMessage = (data) => {
    const timestamp = Date.now()
    const streamId = sessionStorage.getItem('SelectedStream')
    const listenerMessagesRef = ref(db, 'v2/' + streamId + '/listener_messages/' + timestamp)
    set(listenerMessagesRef, data)
  }

  const handleFilesViewShow = () => {
    if (tooltipsActive) {
      return
    }
    setState((prevState) => {
      return {
        ...prevState,
        showFiles: true,
        hasUnreadMessages: false,
        isModalActive: true,
      }
    })
  }

  const handleFilesViewClose = () => {
    setState((prevState) => {
      return {
        ...prevState,
        showFiles: false,
        hasUnreadMessages: false,
        isModalActive: false,
      }
    })
  }

  const handleFileViewShow = (url, isPdf) => {
    setState((prevState) => {
      return {
        ...prevState,
        showImage: true,
        showImageUrl: url,
        isPdf: isPdf,
      }
    })
  }

  const handleFileViewClose = () => {
    setState((prevState) => {
      return {
        ...prevState,
        showImage: false,
        isPdf: false,
      }
    })
  }

  const presenterMessagesView = () => {
    const hasMessages = state.presenterMessagesParsed.length > 0
    return (
      <div className='presenterMessages'>
        <div
          className='translation-row translation-row-first messages-control'
          onClick={handleFilesViewShow}
        >
          <>
            {hasMessages && state.hasUnreadMessages ? (
              <div className='presenter-message-inner'>
                <img src={notificationSvgRedDot} />
                <div className='messages-text' ref={sharedFilesTooltip.ref.refs.setReference}>
                  {t('streamPlayerPage.sharedFiles')}
                </div>
              </div>
            ) : (
              <div className='presenter-message-inner'>
                <img src={notificationSvg} />
                <div className='messages-text' ref={sharedFilesTooltip.ref.refs.setReference}>
                  {t('streamPlayerPage.sharedFiles')}
                </div>
              </div>
            )}
          </>
          {sharedFilesTooltip.isMounted && (
            <Tooltip
              ref={sharedFilesTooltip.ref}
              floatingStyles={sharedFilesTooltip.floatingStyles}
              styles={sharedFilesTooltip.styles}
              content={sharedFilesTooltipContent}
              ARROW_HEIGHT={sharedFilesTooltip.ARROW_HEIGHT}
              ARROW_WIDTH={sharedFilesTooltip.ARROW_WIDTH}
              fill={sharedFilesTooltip.fill}
              handleTooltipDismiss={handleTooltipDismiss}
              handleTooltipNext={handleTooltipNext}
              context={sharedFilesTooltip.context}
            />
          )}
          <div style={{ marginRight: '15px' }}>{state.presenterMessagesParsed.length}</div>
        </div>
        <div
          className='translation-row translation-row-last messages-control'
          onClick={handleMessagesViewShow}
        >
          <div className='presenter-message-inner'>
            <img src={messagesSvg} />
            <div className='messages-text' ref={messagesTooltip.ref.refs.setReference}>
              {t('streamPlayerPage.messages')}
            </div>
          </div>
          {messagesTooltip.isMounted && (
            <Tooltip
              ref={messagesTooltip.ref}
              floatingStyles={messagesTooltip.floatingStyles}
              styles={messagesTooltip.styles}
              content={messagesTooltipContent}
              ARROW_HEIGHT={messagesTooltip.ARROW_HEIGHT}
              ARROW_WIDTH={messagesTooltip.ARROW_WIDTH}
              fill={messagesTooltip.fill}
              handleTooltipDismiss={handleTooltipDismiss}
              handleTooltipNext={handleTooltipNext}
              context={messagesTooltip.context}
            />
          )}
          <div style={{ marginRight: '15px', color: '#0d75a0' }} className='messages-text'>
            {t('streamPlayerPage.new')}
          </div>
        </div>
      </div>
    )
  }

  const handleChangeTranslation = () => {
    if (tooltipsActive) {
      return
    }
    // props.history.replace("/select-translator")
    history.goBack()
  }

  const translationView = () => {
    const { hasTranslation } = state

    if (hasTranslation) {
      return (
        <div className='small-link' onClick={handleChangeTranslation}>
          <span style={{ color: '#0d75a0' }} ref={changeLanguageTooltip.ref.refs.setReference}>
            {t('streamPlayerPage.changeTranslation')}
          </span>
          {changeLanguageTooltip.isMounted && (
            <Tooltip
              ref={changeLanguageTooltip.ref}
              floatingStyles={changeLanguageTooltip.floatingStyles}
              styles={changeLanguageTooltip.styles}
              content={changeLanguageTooltipContent}
              ARROW_HEIGHT={changeLanguageTooltip.ARROW_HEIGHT}
              ARROW_WIDTH={changeLanguageTooltip.ARROW_WIDTH}
              fill={changeLanguageTooltip.fill}
              handleTooltipDismiss={handleTooltipDismiss}
              handleTooltipNext={handleTooltipNext}
              context={changeLanguageTooltip.context}
            />
          )}
        </div>
      )
    } else {
      return <div />
    }
  }

  const convertToMinAndSeconds = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60
    let minStr = `${minutes}`
    let secStr = `${seconds}`
    if (minutes < 10) {
      minStr = '0' + minStr
    }
    if (secStr < 10) {
      secStr = '0' + secStr
    }
    return minStr + ':' + secStr
  }

  const transcriptionView = () => {
    const { transcription, showTranscription } = state
    if (!showTranscription) {
      return <></>
    }

    return <div className='transcription-container'>
      {
        (transcription.length !== 0) ? <div className='transcription'>{transcription}</div> : <></>
      }
    </div>
  }

  const activeTrackView = () => {
    const { trackDisplay, trackTitle } = state

    // if (true) {
    if (trackDisplay) {
      return (
        <div className='active-track'>
          {/* <div className="active-track-playing">
                        <span>{t("streamPlayerPage.nowPlaying")}</span>
                    </div> */}
          <div className='btn-secondary-track' id='start_play_button'>
            <div className='btn-secondary-track-contents'>
              <div className='buttonOverflowEllipsis'>
                {trackTitle}
                {/* Monumento a Cristina di Svezia */}
              </div>
              <Lottie animationData={animationData} style={{ width: '24px', height: '24px' }} />
            </div>
            {/* <div className='btn-secondary-track-contents' style={{"color":"#7c7da0"}}>
                            <div style={{"marginLeft":"5px"}}>{convertToMinAndSeconds(Math.floor(howler.current?.seek()))}</div>
                            <div style={{"marginRight":"5px"}}>{convertToMinAndSeconds(state.currentTrackDuration)}</div>
                        </div> */}
            {/* <div className='btn-secondary-track-contents'>
                            <div className='audio-progress-container'>
                                <div className='audio-progress' style={{ width: `${state.audioProgress}%` }}></div>
                            </div>
                        </div> */}
          </div>
        </div>
      )
    } else {
      return <div />
    }
  }

  const messagesView = () => {
    const generateMessagesViewBody = () => {
      const data = state.listenerMessagesParsed.map((elem) => {
        const elemKey = Object.keys(elem)[0]
        console.log('messages: unix timestamp', elemKey)
        const date = new Date(Number(elemKey))
        console.log('messages: timestamp: date', date)
        const hour = date.getHours()
        console.log('messages: timestamp: hour', hour)
        const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
        console.log('messages: timestamp: minutes', minutes)
        const time = hour + ':' + minutes

        return (
          <div key={elemKey} className='singleMessage'>
            <div className='voxMessage'>{elem[elemKey]['message']}</div>
            <div className='voxTimestamp'>{time}</div>
          </div>
        )
      })

      return (
        <div className='voxModalMessagesBody'>
          <div className='messageTitle'>
            {t('streamPlayerPage.questions')}
            <img src={newMessageSvg} onClick={handleMessagesViewClose} />
          </div>
          {data}
        </div>
      )
    }
    return (
      <VoxMessagesModal
        elemRef={scrollContainerRef}
        t={t}
        show={state.showMessages}
        close={handleMessagesViewClose}
        body={generateMessagesViewBody()}
        handleNewMessageViewShow={handleNewMessageViewShow}
      />
    )
  }

  const newMessageView = () => {
    const submitForm = (e) => {
      e.preventDefault()
      const formData = new FormData(e.target)
      const payload = Object.fromEntries(formData)
      console.log('messages new: senderName and message', payload)
    }
    return (
      <VoxNewMessageModal
        t={t}
        show={state.showNewMessage}
        close={handleNewMessageViewClose}
        writeListenerMessage={writeListenerMessage}
        handleNewMessageViewClose={handleNewMessageViewClose}
      />
    )
  }
  const filesView = () => {
    const generateFilesViewBody = () => {
      const data = state.presenterMessagesParsed.map((elem) => {
        const elemKey = Object.keys(elem)[0]
        const isPdf = elem[elemKey]['mime_type'].toLowerCase().includes('pdf')
        return (
          <div
            className='voxModalBodyContent'
            key={elem[elemKey]['createdAt']}
            onClick={() => handleFileViewShow(elem[elemKey]['link'], isPdf)}
          >
            {isPdf ? (
              <>
                <LazyLoadImage src={placeholder} width={168} height={212} />
              </>
            ) : (
              <div className='image-container'>
                <LazyLoadImage
                  src={elem[elemKey]['link']}
                  width={168}
                  height={212}
                  placeholderSrc={placeholder}
                  effect='blur'
                />
              </div>
            )}
          </div>
        )
      })

      return (
        <div className='voxModalBodyContentContainer'>
          <div className='voxModalBodyContentHeader'>
            <div>{t('streamPlayerPage.sharedFiles')}</div>
            {/* <div>Shared Files</div> */}
            <img src={notificationSvg} onClick={handleFilesViewClose} />
          </div>
          <div className='voxModalBody'>{data}</div>
        </div>
      )
    }
    return (
      <VoxModal
        elemRef={scrollContainerRef}
        show={state.showFiles}
        close={handleFilesViewClose}
        body={generateFilesViewBody()}
      />
    )
  }

  const fileView = () => {
    const defaultPDFPlugin = defaultLayoutPlugin()
    const generateImageBody = () => {
      return (
        <div className='voxModalFileContainer'>
          <div className='voxModalFileBody'>
            <div className='voxModalBodyContentHeader'>
              <div>{t('streamPlayerPage.sharedFiles')}</div>
              {/* <div>Shared Files</div> */}
              <img src={closebtnv1} onClick={handleFileViewClose} />
            </div>
            <div className='image-detail-container'>
              <LazyLoadImage
                src={state.showImageUrl}
                placeholderSrc={placeholder}
                width={'100%'}
                height={'100%'}
                effect='blur'
              />
            </div>
          </div>
        </div>
      )
    }
    return (
      <>
        <VoxModal
          elemRef={scrollContainerRef}
          show={state.showImage}
          close={handleFileViewClose}
          body={generateImageBody()}
        />
        <VoxPdfModal
          t={t}
          showImageUrl={state.showImageUrl}
          handleFileViewClose={handleFileViewClose}
          pdfPlugin={defaultPDFPlugin}
          show={state.isPdf}
        />
      </>
    )
  }

  const { trackDisplay, isWaiting, hasPlayError } = state
  let logoClass = 'App-logo'
  if ((isWaiting || hasPlayError) && trackDisplay) {
    logoClass = 'App-logo-xsm'
  } else if (trackDisplay) {
    // changed from App-logo-sm
    logoClass = 'App-logo-xsm'
  }
  return (
    <
      // initial={{ opacity: 0, x: "100%" }}
      // animate={{ opacity: 1, x:0 }}
      // // exit={{ opacity: 0, x:"-100%" }}
      // transition={{ ease: "easeIn", duration: 0.1 }}
      // style={{ position: "absolute", width: "100%", height: "100%"}}
    >
      <div className='Player'>
        {/* <div className="Player-streamplayer-child">
          <div className="Player-inner-child"> */}
        <header className='App-header-player'>
          {state.showFiles || state.showImage || state.showMessages || state.showNewMessage ? (
            <div className='logo-presenter-block-player'>
              <img src={logo} className='App-logo-v1' style={{ visibility: 'hidden' }} alt='logo' />
            </div>
          ) : (
            <div className='logo-presenter-block-player'>
              <img src={logo} className='App-logo-v1' alt='logo' />
            </div>
          )}
          {messagesView()}
          {newMessageView()}
          {filesView()}
          {fileView()}
          {mainView()}
          {transcriptionView()}
          {translationView()}
          <div className='player-main-body'>
            {presenterMessagesView()}
            <video hidden id='remoteVideo' autoPlay controls playsInline></video>
            {activeTrackView()}
          </div>

          <div className='player-footer-player'>{buttonView()}</div>
        </header>
        {/* </div>
            </div> */}
      </div>
      <div className='infoBtnWrapper'>
        <button className='infoBtn' onClick={handleInfoModalToggle}>
          <img src={infoBtn} className="infoBtnImg" />
        </button>
      </div>
      <InfoModal show={state.showInfo} close={handleInfoModalToggle} />
    </>
  )
}

const VoxMessagesModal = ({ elemRef, t, show, close, body, handleNewMessageViewShow }) => {
  let startY = 0
  let scrollY = 0
  const handleTouchStart = (event) => {
    startY = event.touches[0].clientY
    if (elemRef.current) {
      scrollY = elemRef.current.scrollTop
    }
  }

  const handleTouchEnd = (event) => {
    const endY = event.changedTouches[0].clientY
    const distance = endY - startY

    if (elemRef.current) {
      const scrollPosition = elemRef.current.scrollTop
      if (scrollPosition > 0 || scrollY > 0) {
        return
      }
    }
    // Adjust the threshold based on your preference
    const swipeThreshold = 50

    if (distance > swipeThreshold) {
      close()
    }
  }
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className='voxModalWrappper'
          key='voxMessagesModal'
          variants={voxModalWrapperAnimation}
          initial='hidden'
          animate='visible'
          transition={{ ease: 'easeIn', duration: 0.2 }}
          exit={{
            opacity: 0,
            y: '100%',
            transition: { ease: 'easeIn', duration: 0.2 },
          }}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          {/* <button onClick={() => close()} className='closeVoxModal'>
                        <img src={closebtn} className="closeBtn" />
                    </button> */}
          <div className='voxModalBackground'>
            <div className='voxModalTitle'>
              <img src={logo} className='App-logo-modal-v1' />
            </div>
            <div ref={elemRef} className='voxModalMessagesContainer'>
              {body}
            </div>
          </div>
          <div className='newMessage' onClick={handleNewMessageViewShow}>
            {t('streamPlayerPage.new')}
            <img src={penSvg} />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const VoxNewMessageModal = ({
  t,
  show,
  close,
  writeListenerMessage,
  handleNewMessageViewClose,
}) => {
  let startY = 0
  const handleTouchStart = (event) => {
    startY = event.touches[0].clientY
  }

  const handleTouchEnd = (event) => {
    const endY = event.changedTouches[0].clientY
    const distance = endY - startY

    // Adjust the threshold based on your preference
    const swipeThreshold = 50

    if (distance > swipeThreshold) {
      handleNewMessageViewClose()
    }
  }

  const submitForm = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const payload = Object.fromEntries(formData)
    console.log('messages new: senderName and message', payload)
    const listenerId = sessionStorage.getItem('VoxConnectListenerId')
    const sendername = sessionStorage.setItem('voxSenderName', payload.senderName)
    const data = {
      id: listenerId, // <- User Id ( random UUID )
      // "isFavorite": false,
      message: payload.senderMessage,
      username: payload.senderName,
    }

    writeListenerMessage(data)
    handleNewMessageViewClose()
  }
  const sendername = sessionStorage.getItem('voxSenderName')
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className='voxModalWrappper'
          variants={voxModalWrapperAnimation}
          initial='hidden'
          animate='visible'
          transition={{ ease: 'easeIn', duration: 0.2 }}
          exit={{
            opacity: 0,
            y: '100%',
            transition: { ease: 'easeIn', duration: 0.2 },
          }}
          key='voxNewMessageModal'
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          {/* <button onClick={() => close()} className='closeVoxModal'>
                        <img src={closebtn} className="closeBtn" />
                    </button> */}
          <div className='voxModalBackground'>
            <div className='voxModalTitle'>
              <img src={logo} className='App-logo-modal-v1' />
            </div>
            <div className='voxModalMessagesContainer'>
              <div className='voxModalMessagesBody'>
                <div className='messageTitle'>
                  <div>{t('streamPlayerPage.newQuestions')}</div>
                  <img src={newMessageSvg} onClick={handleNewMessageViewClose} />
                </div>
                <form className='createMessage' onSubmit={submitForm}>
                  <div className='messageName'>
                    {/* <label>{t("streamPlayerPage.name")}</label> */}
                    <input
                      type='text'
                      defaultValue={sendername}
                      name='senderName'
                      placeholder={t('streamPlayerPage.name')}
                      required
                    />
                  </div>
                  <div className='messageText'>
                    {/* <label>{t("streamPlayerPage.message")}</label> */}
                    <textarea
                      required
                      rows={10}
                      placeholder={t('streamPlayerPage.message')}
                      name='senderMessage'
                    ></textarea>
                  </div>
                  <div className='sendMessageOuter'>
                    <button className='sendMessage' type='submit'>
                      {t('streamPlayerPage.send')}
                      <img style={{ marginLeft: '5px' }} src={airplaneSvg} />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const VoxModal = ({ elemRef, show, close, body }) => {
  let startY = 0
  let scrollY = 0
  const handleTouchStart = (event) => {
    startY = event.touches[0].clientY
    if (elemRef.current) {
      scrollY = elemRef.current.scrollTop
    }
  }

  const handleTouchEnd = (event) => {
    const endY = event.changedTouches[0].clientY
    const distance = endY - startY

    if (elemRef.current) {
      const scrollPosition = elemRef.current.scrollTop
      if (scrollPosition > 0 || scrollY > 0) {
        return
      }
    }
    // Adjust the threshold based on your preference
    const swipeThreshold = 50

    if (distance > swipeThreshold) {
      close()
    }
  }
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className='voxModalWrappper'
          key='voxModal'
          variants={voxModalWrapperAnimation}
          initial='hidden'
          animate='visible'
          transition={{ ease: 'easeIn', duration: 0.2 }}
          exit={{
            opacity: 0,
            y: '100%',
            transition: { ease: 'easeIn', duration: 0.2 },
          }}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          {/* <button onClick={() => close()} className='closeVoxModal'>
                        <img src={closebtn} className="closeBtn" />
                    </button> */}
          <div className='voxModalBackground'>
            <div className='voxModalTitle'>
              <img src={logo} className='App-logo-modal-v1' />
              {/* <img src={logo} className="App-logo-v1" className='modaltitle' /> */}
            </div>
            <div ref={elemRef} className='voxModalMessagesContainer'>
              {body}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const VoxPdfModal = ({ t, show, showImageUrl, handleFileViewClose, pdfPlugin }) => {
  let startY = 0
  const handleTouchStart = (event) => {
    startY = event.touches[0].clientY
  }

  const handleTouchEnd = (event) => {
    const endY = event.changedTouches[0].clientY
    const distance = endY - startY

    // Adjust the threshold based on your preference
    const swipeThreshold = 50

    if (distance > swipeThreshold) {
      handleFileViewClose()
    }
  }
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className='voxModalWrappper'
          key='voxPdfModal'
          variants={voxModalWrapperAnimation}
          initial='hidden'
          animate='visible'
          transition={{ ease: 'easeIn', duration: 0.2 }}
          exit={{
            opacity: 0,
            y: '100%',
            transition: { ease: 'easeIn', duration: 0.2 },
          }}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          {/* <button onClick={() => handleFileViewClose()} className='closeVoxModal'>
                        <img src={closebtn} className="closeBtn" />
                    </button> */}
          <div className='voxModalBackground'>
            <div className='voxModalTitle'>
              <img src={logo} className='App-logo-modal-v1' />
            </div>
            <div className='voxModalMessagesContainer'>
              <div className='voxModalFileContainer'>
                <div className='voxModalFileBody'>
                  <div className='voxModalBodyContentHeader'>
                    <div>{t('streamPlayerPage.sharedFiles')}</div>
                    {/* <div>Shared Files</div> */}
                    <img src={closebtnv1} onClick={handleFileViewClose} />
                  </div>
                  <div className='image-detail-container'>
                    <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
                      <Viewer fileUrl={showImageUrl} plugins={[pdfPlugin]}></Viewer>
                    </Worker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const initializeStreamEventLog = () => {
  const data = {
    username: sessionStorage.getItem('VoxConnectListenerId'),
    timestamp: Math.round(Date.now() / 1000),
    app_version: '1.0.' + window.VERSION.slice(0, 8),
    source: 'web',
    event: 'initialize_stream',
    metadata: {
      stream_id: sessionStorage.getItem('SelectedStream'),
      selected_language: sessionStorage.getItem('LangId'),
    },
  }
  postLogs(data)
}

const joinRoomSuccessEventLog = () => {
  const data = {
    username: sessionStorage.getItem('VoxConnectListenerId'),
    timestamp: Math.round(Date.now() / 1000),
    app_version: '1.0.' + window.VERSION.slice(0, 8),
    source: 'web',
    event: 'join_stream_success',
    metadata: {
      stream_id: sessionStorage.getItem('SelectedStream'),
      selected_language: sessionStorage.getItem('LangId'),
    },
  }
  postLogs(data)
}

const alreadyLoggedInEventLog = () => {
  const data = {
    username: sessionStorage.getItem('VoxConnectListenerId'),
    timestamp: Math.round(Date.now() / 1000),
    app_version: '1.0.' + window.VERSION.slice(0, 8),
    source: 'web',
    event: 'already_logged_in',
    metadata: {
      stream_id: sessionStorage.getItem('SelectedStream'),
      selected_language: sessionStorage.getItem('LangId'),
    },
  }
  postLogs(data)
}

const loginToRoomEventLog = () => {
  const data = {
    username: sessionStorage.getItem('VoxConnectListenerId'),
    timestamp: Math.round(Date.now() / 1000),
    app_version: '1.0.' + window.VERSION.slice(0, 8),
    source: 'web',
    event: 'login_for_auth',
    metadata: {
      stream_id: sessionStorage.getItem('SelectedStream'),
      selected_language: sessionStorage.getItem('LangId'),
    },
  }
  postLogs(data)
}

const requestAuthEventLog = () => {
  const data = {
    username: sessionStorage.getItem('VoxConnectListenerId'),
    timestamp: Math.round(Date.now() / 1000),
    app_version: '1.0.' + window.VERSION.slice(0, 8),
    source: 'web',
    event: 'request_auth',
    metadata: {
      stream_id: sessionStorage.getItem('SelectedStream'),
      selected_language: sessionStorage.getItem('LangId'),
    },
  }
  postLogs(data)
}
const authResponseSuccessEventLog = () => {
  const data = {
    username: sessionStorage.getItem('VoxConnectListenerId'),
    timestamp: Math.round(Date.now() / 1000),
    app_version: '1.0.' + window.VERSION.slice(0, 8),
    source: 'web',
    event: 'auth_success',
    metadata: {
      stream_id: sessionStorage.getItem('SelectedStream'),
      selected_language: sessionStorage.getItem('LangId'),
    },
  }
  postLogs(data)
}
const authResponseFailureEventLog = () => {
  const data = {
    username: sessionStorage.getItem('VoxConnectListenerId'),
    timestamp: Math.round(Date.now() / 1000),
    app_version: '1.0.' + window.VERSION.slice(0, 8),
    source: 'web',
    event: 'auth_failure',
    metadata: {
      stream_id: sessionStorage.getItem('SelectedStream'),
      selected_language: sessionStorage.getItem('LangId'),
    },
  }
  postLogs(data)
}
const receivedAudioEventLog = (activeId, title) => {
  const data = {
    username: sessionStorage.getItem('VoxConnectListenerId'),
    timestamp: Math.round(Date.now() / 1000),
    app_version: '1.0.' + window.VERSION.slice(0, 8),
    source: 'web',
    event: 'received_prerecorded_audio',
    metadata: {
      stream_id: sessionStorage.getItem('SelectedStream'),
      selected_language: sessionStorage.getItem('LangName'), // <-- language id
      audio_id: activeId,
      audio_title: title,
    },
  }
  postLogs(data)
}
const receivedFileEventLog = () => {
  const data = {
    username: sessionStorage.getItem('VoxConnectListenerId'),
    timestamp: Math.round(Date.now() / 1000),
    app_version: '1.0.' + window.VERSION.slice(0, 8),
    source: 'web',
    event: 'received_file',
    metadata: {
      stream_id: sessionStorage.getItem('SelectedStream'),
    },
  }
  postLogs(data)
}
export default StreamPlayer
