import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { envFlags } from "./js/utils";
import "./i18n";
import { AnimatedRoutes } from "./components/animatedRoutes";
import { ToastContainer } from 'react-toastify';

export const EnvFlagsContext = React.createContext();

window.VERSION = process.env.REACT_APP_VERSION;

ReactDOM.render(
  <React.StrictMode>
    {!envFlags().isProd ? <div className="domainFlag" /> : <></>}

    <EnvFlagsContext.Provider value={envFlags()}>
    <Router>
        <div>
          <ToastContainer/>
          <AnimatedRoutes />
        </div>
      </Router>
    </EnvFlagsContext.Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
