import flag_ar from "../images/flag_ar.png";
import flag_am from "../images/flag_am.png";
import flag_bg from "../images/flag_bg.png";
import flag_zz from "../images/flag_zz.png";
import flag_cat from "../images/flag_cat.png";
import flag_zh from "../images/flag_zh.png";
import flag_hr from "../images/flag_hr.png";
import flag_cs from "../images/flag_cs.png";
import flag_da from "../images/flag_da.png";
import flag_de from "../images/flag_de.png";
import flag_nl from "../images/flag_nl.png";
import flag_en from "../images/flag_en.png";
import flag_fi from "../images/flag_fi.png";
import flag_fr from "../images/flag_fr.png";
import flag_he from "../images/flag_he.png";
import flag_hu from "../images/flag_hu.png";
import flag_it from "../images/flag_it.png";
import flag_ja from "../images/flag_ja.png";
import flag_ko from "../images/flag_ko.png";
import flag_lb from "../images/flag_lb.png";
import flag_no from "../images/flag_no.png";
import flag_pl from "../images/flag_pl.png";
import flag_pt from "../images/flag_pt.png";
import flag_ro from "../images/flag_ro.png";
import flag_ru from "../images/flag_ru.png";
import flag_sl from "../images/flag_sl.png";
import flag_es from "../images/flag_es.png";
import flag_sv from "../images/flag_sv.png";
import flag_tr from "../images/flag_tr.png";
import translation from "../images/translation.png";


const languages = {
    "ab": "Abkhaz",
    "aa": "Afar",
    "af": "Afrikaans",
    "ak": "Akan",
    "sq": "Albanian",
    "am": "Amharic",
    "ar": "Arabic",
    "an": "Aragonese",
    "hy": "Armenian",
    "as": "Assamese",
    "av": "Avaric",
    "ae": "Avestan",
    "ay": "Aymara",
    "az": "Azerbaijani",
    "bm": "Bambara",
    "ba": "Bashkir",
    "eu": "Basque",
    "be": "Belarusian",
    "bn": "Bengali",
    "bh": "Bihari",
    "bi": "Bislama",
    "bs": "Bosnian",
    "br": "Breton",
    "bg": "Bulgarian",
    "my": "Burmese",
    "ca": "Catalan",
    "ch": "Chamorro",
    "ce": "Chechen",
    "ny": "Nyanja",
    "zh": "Chinese",
    "cv": "Chuvash",
    "kw": "Cornish",
    "co": "Corsican",
    "cr": "Cree",
    "hr": "Croatian",
    "cs": "Czech",
    "da": "Danish",
    "dv": "Divehi",
    "nl": "Dutch",
    "dz": "Dzongkha",
    "en": "English",
    "eo": "Esperanto",
    "et": "Estonian",
    "ee": "Ewe",
    "fo": "Faroese",
    "fj": "Fijian",
    "fi": "Finnish",
    "fr": "French",
    "ff": "Fula",
    "gl": "Galician",
    "ka": "Georgian",
    "de": "German",
    "el": "Greek",
    "gn": "Guaran",
    "gu": "Gujarati",
    "ht": "Haitian",
    "ha": "Hausa",
    "he": "Hebrew",
    "hz": "Herero",
    "hi": "Hindi",
    "ho": "Hiri Motu",
    "hu": "Hungarian",
    "ia": "Interlingua",
    "id": "Indonesian",
    "ie": "Interlingue",
    "ga": "Irish",
    "ig": "Igbo",
    "ik": "Inupiaq",
    "io": "Ido",
    "is": "Icelandic",
    "it": "Italian",
    "iu": "Inuktitut",
    "ja": "Japanese",
    "jv": "Javanese",
    "kl": "Kalaallisut",
    "kn": "Kannada",
    "kr": "Kanuri",
    "ks": "Kashmiri",
    "kk": "Kazakh",
    "km": "Khmer",
    "ki": "Kikuyu",
    "rw": "Kinyarwanda",
    "ky": "Kyrgyz",
    "kv": "Komi",
    "kg": "Kongo",
    "ko": "Korean",
    "ku": "Kurdish",
    "kj": "Kwanyama",
    "la": "Latin",
    "lb": "Luxembourgish",
    "lg": "Ganda",
    "li": "Limburgish",
    "ln": "Lingala",
    "lo": "Lao",
    "lt": "Lithuanian",
    "lu": "Luba-Katanga",
    "lv": "Latvian",
    "gv": "Manx",
    "mk": "Macedonian",
    "mg": "Malagasy",
    "ms": "Malay",
    "ml": "Malayalam",
    "mt": "Maltese",
    "mi": "MÄori",
    "mr": "Marathi",
    "mh": "Marshallese",
    "mn": "Mongolian",
    "na": "Nauru",
    "nv": "Navajo",
    "nd": "North Ndebele",
    "ne": "Nepali",
    "ng": "Ndonga",
    "no": "Norwegian",
    "ii": "Nuosu",
    "nr": "South Ndebele",
    "oc": "Occitan",
    "oj": "Ojibwa",
    "om": "Oromo",
    "or": "Oriya",
    "os": "Ossetic",
    "pa": "Punjabi",
    "pi": "PÄli",
    "fa": "Persian",
    "pl": "Polish",
    "ps": "Pashto",
    "pt": "Portuguese",
    "qu": "Quechua",
    "rm": "Romansh",
    "rn": "Kirundi",
    "ro": "Romanian",
    "ru": "Russian",
    "sa": "Sanskrit",
    "sc": "Sardinian",
    "sd": "Sindhi",
    "se": "Northern Sami",
    "sm": "Samoan",
    "sg": "Sango",
    "sr": "Serbian",
    "gd": "Gaelic",
    "sn": "Shona",
    "si": "Sinhalese",
    "sk": "Slovak",
    "sl": "Slovene",
    "so": "Somali",
    "st": "Southern Sotho",
    "es": "Spanish",
    "su": "Sundanese",
    "sw": "Swahili",
    "ss": "Swati",
    "sv": "Swedish",
    "ta": "Tamil",
    "te": "Telugu",
    "tg": "Tajik",
    "th": "Thai",
    "ti": "Tigrinya",
    "bo": "Tibetan",
    "tk": "Turkmen",
    "tl": "Tagalog",
    "tn": "Tswana",
    "to": "Tonga",
    "tr": "Turkish",
    "ts": "Tsonga",
    "tt": "Tatar",
    "tw": "Twi",
    "ty": "Tahitian",
    "ug": "Uyghur",
    "uk": "Ukrainian",
    "ur": "Urdu",
    "uz": "Uzbek",
    "ve": "Venda",
    "vi": "Vietnamese",
    "vo": "VolapÃ¼k",
    "wa": "Walloon",
    "cy": "Welsh",
    "wo": "Wolof",
    "fy": "Western Frisian",
    "xh": "Xhosa",
    "yi": "Yiddish",
    "yo": "Yoruba",
    "za": "Zhuang",
    "zu": "Zulu"
};

const flagImage = (code: String) => {
    switch (code) {
        case "ar": return flag_ar
        case "am": return flag_am
        case "bg": return flag_bg
        case "zz": return flag_zz
        case "ca": return flag_cat
        case "zh": return flag_zh
        case "hr": return flag_hr
        case "cs": return flag_cs
        case "da": return flag_da
        case "de": return flag_de
        case "nl": return flag_nl
        case "en": return flag_en
        case "fi": return flag_fi
        case "fr": return flag_fr
        case "he": return flag_he
        case "hu": return flag_hu
        case "it": return flag_it
        case "ja": return flag_ja
        case "ko": return flag_ko
        case "lb": return flag_lb
        case "no": return flag_no
        case "pl": return flag_pl
        case "pt": return flag_pt
        case "ro": return flag_ro
        case "ru": return flag_ru
        case "sl": return flag_sl
        case "es": return flag_es
        case "sv": return flag_sv
        case "tr": return flag_tr
        default: return translation
    }
};

export const getLanguageForCode = (code) => {
    if (code in languages) {
        return {
           name: languages[code],
           flagImage: flagImage(code),
        };
    }

    return null;
}

export const getLanguageForName = (name) => {
    for (const key in languages) {
        const languageName = languages[key]
        if (languages[key] === name) {
            return {
                name: languageName,
                flagImage: flagImage(key),
            };
        }
    }

    return null;
}
