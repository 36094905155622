export const envFlags = () => {
  const url = `${window.location.protocol}//${window.location.hostname}`
  const prod = "https://web.voxconnect.app"
  const staging = "https://voxconnect-react-staging.onrender.com"
  return {
    isProd: url === prod,

    // == We might use this in the future ==
    // isStaging: url === staging,
    // isDevelopment: url === "http://localhost"
  }
}