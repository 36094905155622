import React from "react";
import { Route, BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import JoinStream from "../JoinStream";
import StreamPlayer from "../StreamPlayer";
import TranslatorList from "../TranslatorList";
import { AnimatePresence } from "framer-motion";

export function AnimatedRoutes() {
    const location = useLocation();
  return (
          <AnimatePresence>
          <Switch location={location} key={location.pathname}>
            <Route path="/select-translator" component={TranslatorList} />
            <Route path="/play" component={StreamPlayer} />
            <Route path="/:streamId" component={JoinStream} />
            <Route path="/" component={JoinStream} />
          </Switch>
          </AnimatePresence>
  );
}
