import { motion } from 'framer-motion'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RotatingLines } from 'react-loader-spinner'
import { useHistory } from 'react-router-dom'
import './Player.css'
import { ErrNoTranslation, fetchTranslatorRooms, getAudioList, postLogs } from './axios/requests'
import { InfoModal } from './components/modals/info'
import { getLanguageForName } from './helpers/languages'
import infoBtn from './images/v1/info_blue_24.svg'
import backNavBtn from './images/v1/left-arrow.svg'
import img_primary from './images/v1/live.svg'
import img_chevron_right from './images/v1/right-arrow.svg'
import { EnvFlagsContext } from './index'
import './index.css'

const TranslatorList = (props) => {
  const history = useHistory()

  // init translations
  const { t, i18n } = useTranslation()
  useEffect(() => {
    const lng = navigator.language
    i18n.changeLanguage(lng)
  }, [])

  const isProd = useRef(useContext(EnvFlagsContext).isProd)

  const [state, setState] = useState({
    showInfo: false,
    streams: [],
  })

  const playStream = (stream) => {
    sessionStorage.setItem('SelectedStream', stream.id.toUpperCase())
    sessionStorage.setItem('LangId', stream.lang_id)
    sessionStorage.setItem('LangName', stream.name)
    sessionStorage.setItem('LangFlag', stream.image)

    sessionStorage.setItem('ShowCaptions', stream.name === 'Live with captions')

    languageSelectionEventLog()
    // props.history.replace("/play");
    history.push('/play')

    // signOut(voxauth).then(() => {
    // // Sign-out successful.
    //     console.log("Signed out successfully")
    //     props.history.replace("/play")
    // }).catch((error) => {
    //     console.log("Signed out error")
    //     props.history.replace("/")
    // // An error happened.
    // });
  }

  useEffect(() => {
    const streamId = sessionStorage.getItem('StreamName')

    setState((prevState) => {
      return {
        ...prevState,
        primaryStream: streamId,
      }
    })

    fetchTranslatorRooms(streamId, isProd.current)
      .then((response) => {
        generateRoomsList(response, streamId)
      })
      .catch((error) => {
        if (error.message === ErrNoTranslation.message) {
          sessionStorage.setItem('HasTranslation', 'no')
          playStream({ id: streamId })
        } else {
          console.log('error when getting rooms list:', error)
          props.history.replace('/')
        }
      })
  }, [])

  useEffect(() => {
    const translators = state.streams
    if (translators.length === 1) {
      sessionStorage.setItem('HasTranslation', 'no')
      // playStream({
      //   id: translators[0].id,
      //   name: "Live",
      //   image: img_primary,
      // });
    } else {
      sessionStorage.setItem('HasTranslation', 'yes')
    }
  }, [props, state])

  const spinner = () => {
    console.log('Loading Spinner')
    return (
      <header className='App-header-translator'>
        <RotatingLines
          strokeColor='black'
          strokeWidth='5'
          animationDuration='0.75'
          width='96'
          visible={true}
        />
      </header>
    )
  }

  const handleInfoModalToggle = () => {
    setState((prevState) => {
      return {
        ...prevState,
        showInfo: !prevState.showInfo,
      }
    })
  }

  const translatorList = (streams) => {
    console.log('Loading Translators List')
    if (streams.length === 1) {
      playStream(streams[0])
    }
    return (
      <header className='App-header-translator-list'>
        {/* <div className="Translator-inner-child"> */}
        <div className='InputField-Translator'>
          <div style={{ fontSize: '18px', textAlign: 'center' }}>
            {' '}
            {t('translatorListPage.selectTranslation')}{' '}
          </div>
          <div>
            {streams.map((stream, index) => (
              <div
                key={stream.name}
                className={
                  index === 0
                    ? 'translation-row translation-row-first'
                    : index === streams.length - 1
                      ? 'translation-row translation-row-last'
                      : 'translation-row'
                }
                onClick={() => {
                  console.log('Selected Stream : ' + stream.id)
                  playStream(stream)
                }}
              >
                <img src={stream.image} />
                <span>{stream.name}</span>
                <img src={img_chevron_right} />
              </div>
            ))}
          </div>
          {/* </div> */}
        </div>
      </header>
    )
  }

  const generateRoomsList = (response, primaryStreamId) => {
    const popmap_Id = response.data.popmap_id

    if (popmap_Id != null) {
      getAudioList(popmap_Id, primaryStreamId)
        .then((audios) => {
          updateRoomsList(audios, primaryStreamId)
        })
        .catch((error) => {
          console.log('failed to fetch audio list: ' + error)
        })
    } else {
      const translators = response.data.translation_rooms.map((room) => {
        const language = getLanguageForName(room.name)
        return {
          id: room.id,
          name: language.name,
          image: language.flagImage,
        }
      })

      updateRoomsList(translators, primaryStreamId)
    }
  }

  const updateRoomsList = (translators, primaryStreamId) => {
    const primaryStream = [{ id: primaryStreamId, name: 'Live', image: img_primary }]

    const primaryStreamWithCaptions = [
      { id: primaryStreamId, name: 'Live with captions', image: img_primary },
    ]

    console.log(primaryStream)
    console.log(translators)

    const streams = primaryStream.concat(primaryStreamWithCaptions).concat(translators)

    setState((prevState) => {
      return {
        ...prevState,
        streams: streams,
      }
    })
  }

  return (
    <motion.div
      initial={{ opacity: 0, x: history.action === 'POP' ? '-100%' : '100%' }}
      animate={{ opacity: 1, x: 0 }}
      // exit={{ opacity: 0, x:"100%" }}
      transition={{ ease: 'easeIn', duration: 0.1 }}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <div className='Player-translator-list'>
        {state.streams.length <= 0 ? spinner() : translatorList(state.streams)}
      </div>
      <div className='backNavBtnWrapper'>
        <button
          className='backNavBtn'
          onClick={() => {
            history.goBack()
          }}
        >
          <img src={backNavBtn} className='backNavBtnImg' />
        </button>
      </div>
      <div className='infoBtnWrapper'>
        <button className='infoBtn' onClick={handleInfoModalToggle}>
          <img src={infoBtn} className='infoBtnImg' />
        </button>
      </div>
      <InfoModal show={state.showInfo} close={handleInfoModalToggle} />
    </motion.div>
  )
}

const languageSelectionEventLog = () => {
  console.log('language selection Event Log')
  const language = sessionStorage.getItem('LangName')
  const data = {
    username: sessionStorage.getItem('VoxConnectListenerId'),
    timestamp: Math.round(Date.now() / 1000),
    app_version: '1.0.' + window.VERSION.slice(0, 8),
    event: 'select_language',
    metadata: {
      stream_id: sessionStorage.getItem('SelectedStream'),
      selected_language: sessionStorage.getItem('LangId'),
      translator_type: language === 'Live' ? 'live' : 'prerecorded_audio',
    },
  }
  console.log('Language Selection Event Log Data : ', data)
  postLogs(data)
}
export default TranslatorList
