import { envFlags } from "../js/utils"

const isDebug = window.location.href.includes("localhost")

export const Log = {
    d: (message?: any, ...optionalParams: any[]) => {
        if (!envFlags().isProd) {
            console.log(message, optionalParams)
        }
    },
    e: (message?: any, ...optionalParams: any[]) => {
        console.error(message, optionalParams)
    }
}