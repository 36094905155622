// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getDatabase} from "firebase/database"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCikX8UoNcrwlesSEIsaiZe0I_gcmBhOtQ",
  authDomain: "voxconnect-c7655.firebaseapp.com",
  databaseURL: "https://voxconnect-c7655.firebaseio.com",
  projectId: "voxconnect-c7655",
  storageBucket: "voxconnect-c7655.appspot.com",
  messagingSenderId: "734243718792",
  appId: "1:734243718792:web:dc25c0bdb987bf8bd8c13d",
  measurementId: "G-SYQK47S2JM"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getDatabase()
export const voxauth = getAuth(app)