import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          joinStreamPage: {
            startTour: "Start the tour",
            startTourDetail: "Enter the code or scan the QR code provided by your guide to start the guided tour",
            startTourDetailCode: "Here is the code to start your guided tour",
            joinStream: "Connect",
          },
          translatorListPage: {
            changeStream: "Change Stream",
            selectTranslation: "Select Translation",
          },
          streamPlayerPage: {
            changeTranslation: "Change Language",
            tapTheButton: " Tap the button to start playing the stream...",
            waitingForPres: "Waiting for the presenter...",
            newQuestions: "New message",
            questions: "Messages",
            name: "Name",
            message: "Your question or comment",
            new: "Send new",
            send: "Send message",
            disconnect: "Stop Stream",
            startPlayingAudio: "Start Playing Audio!",
            nowPlaying: "Now Playing",
            sharedFiles: "Shared Files",
            messages: "Messages",
            startPlaying: "Start Playing"
          },
          tooltips: {
            switchLanguage: {
                title: "Switch Language",
                description: "Go back to the language list and select your preferred language stream."
            },
            sharedFiles: {
                title: "Shared Files",
                description: "Tap here to view all the files shared by the speaker. \n You can also tap on the individual files to view them in full."
            },
            sendQuestionsOrComments: {
                title: "Send Questions or Comments",
                description: "Have a question or a comment for the speaker? \n Tap here and send the message right away."
            },
            stopTheStream: {
                title: "Stop the Stream",
                description: "Tap the button to disconnect from the stream."
            },
            next: "Next",
            dismiss: "Dismiss",
            done: "Done",
          },
        },
      },
      ja: {
        translation: {
          joinStreamPage: {
            joinStream: "ストリームに参加",
          },
          translatorListPage: {
            changeStream: "ストリームを変更",
            selectTranslation: "翻訳を選択",
          },
          streamPlayerPage: {
            changeTranslation: "翻訳を変更",
            tapTheButton: "ボタンをタップして、ストリームの再生を開始...",
            waitingForPres: "プレゼンターを待っています...",
            newQuestions: "新しい質問/コメント",
            questions: "質問/コメント",
            name: "名前",
            message: "メッセージ",
            new: "新しい",
            send: "送信",
            disconnect: "切断",
            startPlayingAudio: "オーディオの再生を開始!",
            nowPlaying: "今再生中"
          },
          tooltips: {
            switchLanguage: {
              title: "言語を切り替える",
              description: "言語リストに戻り、好みの言語ストリームを選択してください。"
            },
            sharedFiles: {
              title: "共有ファイル",
              description: "「ここをタップして、スピーカーによって共有されたすべてのファイルを表示します。\n  個々のファイルをタップして、フルサイズで表示することもできます。」"
            },
            sendQuestionsOrComments: {
              title: "質問やコメントを送る",
              description: "「スピーカーへの質問やコメントがありますか？ \n  ここをタップして、すぐにメッセージを送信してください。」"
            },
            stopTheStream: {
              title: "ストリームを停止する",
              description: "ストリームから切断するためのボタンをタップします。"
            },
            next: "次へ",
            dismiss: "閉じる",
            done: "完了",
          },
        },
      },
      ru: {
        translation: {
          joinStreamPage: {
            joinStream: "Присоединиться к трансляции",
          },
          translatorListPage: {
            changeStream: "Изменить трансляцию",
            selectTranslation: "Выбрать перевод",
          },
          streamPlayerPage: {
            changeTranslation: "Изменить перевод",
            tapTheButton: "Нажмите кнопку, чтобы начать воспроизведение трансляции...",
            waitingForPres: "Ожидание ведущего...",
            newQuestions: "Новые вопросы/комментарии",
            questions: "Вопросы/Комментарии",
            name: "Имя",
            message: "Сообщение",
            new: "Новый",
            send: "Отправить",
            disconnect: "Отключиться",
            startPlayingAudio: "Начать воспроизведение аудио!",
            nowPlaying: "Сейчас играет"
          },
          tooltips: {
            switchLanguage: {
              title: "Переключить язык",
              description: "Вернитесь к списку языков и выберите предпочитаемый языковой поток."
            },
            sharedFiles: {
              title: "Общие файлы",
              description: "Нажмите здесь, чтобы просмотреть все файлы, поделенные спикером. \n Вы также можете нажать на отдельные файлы, чтобы просмотреть их в полном размере."
            },
            sendQuestionsOrComments: {
              title: "Отправить вопросы или комментарии",
              description: "У вас есть вопрос или комментарий к спикеру? \n Нажмите здесь и отправьте сообщение немедленно."
            },
            stopTheStream: {
              title: "Остановить трансляцию",
              description: "Нажмите на кнопку, чтобы отключиться от трансляции."
            },
            next: "Далее",
            dismiss: "Отклонить",
            done: "Готово",
          },
        },
      },
      fr: {
        translation: {
          joinStreamPage: {
            joinStream: "Rejoindre le flux",
          },
          translatorListPage: {
            changeStream: "Changer de flux",
            selectTranslation: "Sélectionner la traduction",
          },
          streamPlayerPage: {
            changeTranslation: "Changer de traduction",
            tapTheButton: "Appuyez sur le bouton pour commencer à jouer le flux...",
            waitingForPres: "En attente de l'intervenant...",
            newQuestions: "Nouvelles questions/commentaires",
            questions: "Questions/Commentaires",
            name: "Nom",
            message: "Message",
            new: "Nouveau",
            send: "Envoyer",
            disconnect: "Déconnecter",
            startPlayingAudio: "Commencer à jouer l'audio !",
            nowPlaying: "En cours de lecture"
          },
          tooltips: {
            switchLanguage: {
              title: "Changer de langue",
              description: "Retournez à la liste des langues et sélectionnez le flux de langue de votre choix."
            },
            sharedFiles: {
              title: "Fichiers partagés",
              description: "Appuyez ici pour voir tous les fichiers partagés par l'intervenant. \n Vous pouvez également appuyer sur les fichiers individuels pour les voir en entier."
            },
            sendQuestionsOrComments: {
              title: "Envoyer des questions ou des commentaires",
              description: "Vous avez une question ou un commentaire pour l'intervenant ? \n Appuyez ici et envoyez le message immédiatement."
            },
            stopTheStream: {
              title: "Arrêter le flux",
              description: "Appuyez sur le bouton pour vous déconnecter du flux."
            },
            next: "Suivant",
            dismiss: "Ignorer",
            done: "Terminé",
          },
        },
      },
      es: {
        translation: {
          joinStreamPage: {
            joinStream: "Unirse al stream",
          },
          translatorListPage: {
            changeStream: "Cambiar stream",
            selectTranslation: "Seleccionar traducción",
          },
          streamPlayerPage: {
            changeTranslation: "Cambiar traducción",
            tapTheButton: "Toca el botón para empezar a reproducir el stream...",
            waitingForPres: "Esperando al presentador...",
            newQuestions: "Nuevas preguntas/comentarios",
            questions: "Preguntas/Comentarios",
            name: "Nombre",
            message: "Mensaje",
            new: "Nuevo",
            send: "Enviar",
            disconnect: "Desconectar",
            startPlayingAudio: "¡Comienza a reproducir el audio!",
            nowPlaying: "Reproduciendo ahora"
          },
          tooltips: {
            switchLanguage: {
              title: "Cambiar idioma",
              description: "Regresa a la lista de idiomas y selecciona tu transmisión de idioma preferida."
            },
            sharedFiles: {
              title: "Archivos compartidos",
              description: "Toca aquí para ver todos los archivos compartidos por el orador. \n También puedes tocar en los archivos individuales para verlos completos."
            },
            sendQuestionsOrComments: {
              title: "Enviar preguntas o comentarios",
              description: "¿Tienes una pregunta o un comentario para el orador? Toca aquí y envía el mensaje de inmediato."
            },
            stopTheStream: {
              title: "Detener la transmisión",
              description: "Toca el botón para desconectarte de la transmisión."
            },
            next: "Siguiente",
            dismiss: "Descartar",
            done: "Hecho",
          },
        },
      },
      zh: {
        translation: {
          joinStreamPage: {
            joinStream: "加入直播",
          },
          translatorListPage: {
            changeStream: "更改直播",
            selectTranslation: "选择翻译",
          },
          streamPlayerPage: {
            changeTranslation: "更改翻译",
            tapTheButton: "点击按钮开始播放直播...",
            waitingForPres: "等待主持人...",
            newQuestions: "新的问题/评论",
            questions: "问题/评论",
            name: "名字",
            message: "信息",
            new: "新的",
            send: "发送",
            disconnect: "断开连接",
            startPlayingAudio: "开始播放音频!",
            nowPlaying: "正在播放"
          },
          tooltips: {
            switchLanguage: {
              title: "切换语言",
              description: "返回语言列表并选择您偏好的语言流。"
            },
            sharedFiles: {
              title: "共享文件",
              description: "点击这里查看演讲者共享的所有文件。\n 您也可以点击单个文件以全屏查看。"
            },
            sendQuestionsOrComments: {
              title: "发送问题或评论",
              description: "对演讲者有问题或评论？ \n  点击这里立即发送消息。"
            },
            stopTheStream: {
              title: "停止流",
              description: "点击按钮以断开流连接。"
            },
            next: "下一个",
            dismiss: "忽略",
            done: "完成",
          },
        },
      },
      de: {
        translation: {
          joinStreamPage: {
            joinStream: "Stream beitreten",
          },
          translatorListPage: {
            changeStream: "Stream wechseln",
            selectTranslation: "Übersetzung auswählen",
          },
          streamPlayerPage: {
            changeTranslation: "Übersetzung ändern",
            tapTheButton: "Tippen Sie auf die Taste, um den Stream abzuspielen...",
            waitingForPres: "Warten auf den Präsentator...",
            newQuestions: "Neue Fragen/Kommentare",
            questions: "Fragen/Kommentare",
            name: "Name",
            message: "Nachricht",
            new: "Neu",
            send: "Senden",
            disconnect: "Trennen",
            startPlayingAudio: "Audio-Wiedergabe starten!",
            nowPlaying: "Jetzt abspielen"
          },
          tooltips: {
            switchLanguage: {
              title: "Sprache wechseln",
              description: "Gehen Sie zurück zur Sprachenliste und wählen Sie Ihren bevorzugten Sprachstream aus."
            },
            sharedFiles: {
              title: "Geteilte Dateien",
              description: "Tippen Sie hier, um alle vom Sprecher geteilten Dateien anzusehen. \n Sie können auch auf die einzelnen Dateien tippen, um sie vollständig anzusehen."
            },
            sendQuestionsOrComments: {
              title: "Fragen oder Kommentare senden",
              description: "Haben Sie eine Frage oder einen Kommentar für den Sprecher? \n Tippen Sie hier und senden Sie die Nachricht sofort ab."
            },
            stopTheStream: {
              title: "Den Stream stoppen",
              description: "Tippen Sie auf den Button, um die Verbindung zum Stream zu trennen."
            },
            next: "Weiter",
            dismiss: "Schließen",
            done: "Fertig",
          },
        },
      },
      pt: {
        translation: {
          joinStreamPage: {
            joinStream: "Junte-se ao stream",
          },
          translatorListPage: {
            changeStream: "Alterar stream",
            selectTranslation: "Selecionar tradução",
          },
          streamPlayerPage: {
            changeTranslation: "Alterar tradução",
            tapTheButton: "Toque no botão para começar a reproduzir o stream...",
            waitingForPres: "Esperando pelo apresentador...",
            newQuestions: "Novas perguntas/comentários",
            questions: "Perguntas/Comentários",
            name: "Nome",
            message: "Mensagem",
            new: "Novo",
            send: "Enviar",
            disconnect: "Desconectar",
            startPlayingAudio: "Comece a tocar áudio!",
            nowPlaying: "Reproduzindo agora"
          },
          tooltips: {
            switchLanguage: {
              title: "Mudar Idioma",
              description: "Volte à lista de idiomas e selecione o seu fluxo de idioma preferido."
            },
            sharedFiles: {
              title: "Arquivos Compartilhados",
              description: "Toque aqui para ver todos os arquivos compartilhados pelo palestrante. \n Você também pode tocar nos arquivos individuais para visualizá-los por completo."
            },
            sendQuestionsOrComments: {
              title: "Enviar Perguntas ou Comentários",
              description: "Tem uma pergunta ou um comentário para o palestrante? \n Toque aqui e envie a mensagem imediatamente."
            },
            stopTheStream: {
              title: "Parar a Transmissão",
              description: "Toque no botão para se desconectar da transmissão."
            },
            next: "Próximo",
            dismiss: "Descartar",
            done: "Concluído",
          },
        },
      },
      it: {
        translation: {
          joinStreamPage: {
            joinStream: "Unisciti allo stream",
          },
          translatorListPage: {
            changeStream: "Cambia stream",
            selectTranslation: "Seleziona traduzione",
          },
          streamPlayerPage: {
            changeTranslation: "Cambia traduzione",
            tapTheButton: " Tocca il pulsante per iniziare a riprodurre lo stream...",
            waitingForPres: "In attesa del presentatore...",
            newQuestions: "Nuove domande/commenti",
            questions: "Domande/Commenti",
            name: "Nome",
            message: "Messaggio",
            new: "Nuovo",
            send: "Invia",
            disconnect: "Disconnetti",
            startPlayingAudio: "Inizia a riprodurre l'audio!",
            nowPlaying: "Ora in riproduzione"
          },
          tooltips: {
            switchLanguage: {
              title: "Cambia Lingua",
              description: "Torna all'elenco delle lingue e seleziona il flusso linguistico di tua preferenza."
            },
            sharedFiles: {
              title: "File Condivisi",
              description: "Tocca qui per visualizzare tutti i file condivisi dal relatore. \n Puoi anche toccare i singoli file per visualizzarli per intero."
            },
            sendQuestionsOrComments: {
              title: "Invia Domande o Commenti",
              description: "Hai una domanda o un commento per il relatore? \n Tocca qui e invia subito il messaggio."
            },
            stopTheStream: {
              title: "Interrompi il Flusso",
              description: "Tocca il pulsante per disconnetterti dal flusso."
            },
            next: "Successivo",
            dismiss: "Ignora",
            done: "Fatto",
          },
        },
      },
    },
  });

export default i18n;